import React from 'react';
import { makeStyles, Link, Grid, Typography, Button, Container } from '@material-ui/core';

import Header from '../header';
import backgroundImage from '../../assets/images/sectionA-background-Image-1.svg';
import backgroundImageMobile from '../../assets/images/sectionA-background-Image-2.svg';
import Image from '../../assets/icons/icon-bi.inline.png';

const useStyles = makeStyles(theme => ({
  rootContainer: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPositionX: 'right',
    [theme.breakpoints.down('xs')]: {
      backgroundImage: `url(${backgroundImageMobile})`,
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',
    },
    [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(15),
    },
  },
  mainContainer: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12, 0),
    },
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
      minHeight: 300,
      maxWidth: 600,
    },
  },
  button: {
    maxWidth: 240,
    color: '#fff',
  },
}));

export default function SectionA() {
  const classes = useStyles();
  return (
    <section className={classes.rootContainer}>
      <Header />
      <Container>
        <Grid container className={classes.mainContainer}>
          <Grid item xs={12} md={6}>
            <div className={classes.leftContainer}>
              <Typography variant="body1" gutterBottom>
                Business Intelligence will shape the future of your company.
              </Typography>
              <Typography variant="h4" gutterBottom>
                CandleStream offers robust BI solutions suited for
                enterprises of any scale, and built for any number
                of platforms.
              </Typography>
              <Link color="inherit" href="https://portal.candlestream.com">
                <Button variant="contained" color="secondary" fullWidth className={classes.button}>
                  Check out our Demos
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.rightContainer}>
              <img style={{width: '100%'}} src={Image}></img>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
