import React from 'react';
import { makeStyles, Grid, Typography, Container } from '@material-ui/core';

import Icon1 from '../../assets/icons/icon-1.inline.svg';
import Icon2 from '../../assets/icons/icon-6.inline.png';
import Icon3 from '../../assets/icons/icon-3.inline.svg';
import Icon4 from '../../assets/icons/icon-4.inline.svg';

const useStyles = makeStyles(theme => ({
  rootContainer: {},
  mainContainer: {
    padding: theme.spacing(10, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(10, 0, 5),
    },
  },
  textSection: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  cardWrapper: {
    padding: theme.spacing(5, 0),
  },

  textCard: {
    display: 'flex',
    flexDirection: 'column',
    // maxWidth: 532,
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(3),
    },
    '& > *': {
      marginBottom: theme.spacing(3),
    },
  },
}));

export default function SectionB() {
  const classes = useStyles();
  return (
    <section className={classes.rootContainer}>
      <Container>
        <div className={classes.mainContainer}>
          <div className={classes.textSection}>
            <Typography variant="h1" color="textSecondary" gutterBottom>
              What solutions do we offer?
            </Typography>
            <Typography variant="body2" gutterBottom>
              We offer a wide range of BI analytics and integrations.
            </Typography>
          </div>
          <div className={classes.cardWrapper}>
            <Grid container direction="row" spacing={5}>
              <Grid item xs={12} md={6}>
                <div className={classes.textCard}>
                  <Icon1 />
                  <Typography variant="h1">Power BI Reports</Typography>
                  <Typography variant="h4">
                    Something about our BI Reports available through the Portal
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.textCard}>
                  <img src={Icon2} style={{width: '46px'}}></img>
                  <Typography variant="h1">Anvizent Analytics</Typography>
                  <Typography variant="h4">
                    A pitch for Anvizent here
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.textCard}>
                  <Icon3 />
                  <Typography variant="h1">Syspro Integration</Typography>
                  <Typography variant="h4">
                    Something about our history of Syspro development
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.textCard}>
                  <Icon4 />
                  <Typography variant="h1">Data Warehouses</Typography>
                  <Typography variant="h4">
                  Something explaining data warehouses here.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </section>
  );
}
